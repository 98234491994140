@import "../styles/style-config.scss";
@import "../styles/style-functions.scss";

.main {
    margin-top: 30px;
    text-align: center;
    z-index: 5;
    p {
        font-size: 18px;
        margin-top: 0;
    }

    h1 {
        font-size: 95px;
        margin: 0;
    }
}

.fourohfour {
    display: table;
    margin: 0 auto;
    padding: 15px 30px;
    text-align: center;
    text-transform: uppercase;
    background-color: $color-highlight;
    border-radius: 20px;
    cursor: pointer;
    @include transition(all ease-in-out 0.2s);

    &:hover {
        background-color: $color-highlight-light;
        color: $color-highlight;
    }
    &:hover p {
        color: $color-highlight;
      }

    p {
        font-weight: 500;
        color:$color-highlight-light;
        @include transition(color ease-in-out 0.2s);
    }
}
